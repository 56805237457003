import { request, request2 } from "../utils/axios";
import { stringify } from "qs";

// 小程序顶部配置
export function setOptions(params) {
  return request({
    url: `/wuan/admin/wx/set/miniProgram/indexConfig?${stringify(params)}`,
    method: "post",
  });
}
export function getOptions() {
  return request({
    url: `/wx/miniProgram/indexConfig?`,
    method: "get",
  })
}
