<script setup>
import { onMounted, ref } from 'vue'
import { message } from "ant-design-vue";
import { setOptions, getOptions } from '../api/mpSet.js'

const formRef = ref()
const formModal = ref({
  title: '', describe: ''
})
const handleSave = async () => {
  const { status, msg } = await setOptions(formModal.value)
  status == '200' ? message.success(msg) : message.warning(msg)
  queryOptions()
}
const queryOptions = async () => {
  const { data: { title, describe } } = await getOptions()
  formModal.value = { title, describe }
}

onMounted(queryOptions)
</script>
<template>
  <a-form ref="formRef" :model="formModal" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
    <a-form-item label="首页顶部标题" name="title">
      <a-input v-model:value="formModal.title" placeholder="请输入" />
    </a-form-item>
    <a-form-item label="首页顶部描述" name="describe">
      <a-input v-model:value="formModal.describe" placeholder="请输入" />
    </a-form-item>
    <a-form-item label=" " :colon="false">
      <a-button type="primary" @click="handleSave">保存</a-button>
    </a-form-item>
  </a-form>
</template>
